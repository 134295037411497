
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/projectinfo";

@Options({})
export default class extends Vue {
  private userId = "";
  private listData: any[] = [];
  private refreshing = false;
  mounted() {
    this.userId = this.$route.query.userId as string;
    this.getData(this.userId);
  }
  getData(id: any) {
    api
      .listUserFaceSyncItem({
        projectId: this.$store.state.project.projectinfo.projectId,
        userId: id,
      })
      .then((res: any) => {
        this.listData = res.data;
        this.refreshing = false;
      });
  }
  onRefresh() {
    setTimeout(() => {
      this.getData(this.userId);
    }, 1000);
  }
  refresh() {
    this.refreshing = true;
    this.onRefresh();
  }
  syncAll() {
    api
      .syncOneUserToAllDevice({
        projectId: this.$store.state.project.projectinfo.projectId,
        userId: this.userId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$toast.success(res.msg);
          setTimeout(() => {
            this.refreshing = true;
            this.onRefresh();
          }, 2500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }
  syncOne(item: any) {
    api
      .syncOneUserToOneDevice({
        projectId: this.$store.state.project.projectinfo.projectId,
        deviceNo: item.deviceNo,
        userId: this.userId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$toast.success(res.msg);
          setTimeout(() => {
            this.refreshing = true;
            this.onRefresh();
          }, 2500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }
}
